<template>
  <div class="home">
    <ContactUs />
  </div>
</template>

<script>
import ContactUs from "@/components/ContactUs.vue";

export default {
  name: "Contact",
  components: {
    ContactUs,
  },
};
</script>
