<template>
  <div class="about">
    <PrivacyPolicy/>
  </div>
</template>

<script>
// @ is an alias to /src
import PrivacyPolicy from '@/components/PrivacyPolicy.vue'

export default {
  name: 'Privacy',
  components: {
    PrivacyPolicy
  }
}
</script>