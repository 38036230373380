<template>
  <div class="hello">
    <h1>Terms of use</h1>
    <p>Last updated <strong> December 8, 2023 </strong></p>
    <p>Welcome to <strong> ghoulchat! </strong></p>

    <p>
      These terms and conditions outline the rules and regulations for the use
      of ghoulchat.
    </p>

    <p>
      By using this App we assume you accept these terms and conditions. Do not
      continue to use ghoulchat if you do not agree to take all of the terms and
      conditions stated on this page.
    </p>

    <p>
      The following terminology applies to these Terms and Conditions, Privacy
      Statement and Disclaimer Notice and all Agreements: "Client", "You" and
      "Your" refers to you, the person log on this website and compliant to the
      Company’s terms and conditions. "The Company", "Ourselves", "We", "Our"
      and "Us", refers to our Company. "Party", "Parties", or "Us", refers to
      both the Client and ourselves. Any use of the above terminology or other
      words in the singular, plural, capitalization and/or he/she or they, are
      taken as interchangeable and therefore as referring to same.
    </p>
    <p>
      Parts of the App offer an opportunity for users to post and exchange
      opinions and information in certain areas of the App. ghoulchat does not
      filter, edit, publish or review content prior to their presence on the
      App. Content do not reflect the views and opinions of ghoulchat, its
      agents and/or affiliates. Content reflect the views and opinions of the
      person who posts their views and opinions. To the extent permitted by
      applicable laws, ghoulchat shall not be liable for the content or for any
      liability, damages or expenses caused and/or suffered as a result of any
      use of and/or posting of and/or appearance of the content on the App.
    </p>

    <p>
      ghoulchat reserves the right to monitor all content and to remove any
      content which can be considered inappropriate, offensive or causes breach
      of these Terms and Conditions (see User Generated Content below).
    </p>

    <p>You warrant and represent that:</p>

    <ul>
      <li>
        You are entitled to post the content on our appand have all necessary
        licenses and consents to do so;
      </li>
      <li>
        The content do not invade any intellectual property right, including
        without limitation copyright, patent or trademark of any third party;
      </li>
      <li>
        The content do not contain any defamatory, libelous, offensive, indecent
        or otherwise unlawful material which is an invasion of privacy
      </li>
      <li>
        The content will not be used to solicit or promote business or custom or
        present commercial activities or unlawful activity.
      </li>
    </ul>

    <p>
      You hereby grant ghoulchat a non-exclusive license to remove any of your
      content in any and all forms, formats or media, if in violation of these
      terms.
    </p>

    
     <h3><strong>User Generated Content</strong></h3>
    <p>
      The Licensed Application may invite you to chat, contribute to, or
      participate in group chats, including but
      not limited to text, writings, video, audio, photographs, graphics,
      comments, suggestions, or personal information or other material
      (collectively, "Contributions"). Contributions may be viewable only by other
      users of the Licensed Application. When you create or make available
      any Contributions, you thereby represent and warrant that:
    </p>

    <ol>
      <li>
        The creation, distribution, transmission, public display, or
        performance, and the accessing, downloading, or copying of your
        Contributions do not and will not infringe the proprietary rights,
        including but not limited to the copyright, patent, trademark, trade
        secret, or moral rights of any third party.
      </li>

      <li>
        You are the creator and owner of or have the necessary licenses, rights,
        consents, releases, and permissions to use and to authorize us, the
        Licensed Application, and other users of the Licensed Application to use
        your Contributions in any manner contemplated by the Licensed
        Application and this License Agreement.
      </li>

      <li>
        You have the written consent, release, and/or permission of each and
        every identifiable individual person in your Contributions to use the
        name or likeness or each and every such identifiable individual person
        to enable inclusion and use of your Contributions in any manner
        contemplated by the Licensed Application and this License Agreement.
      </li>

      <li>Your Contributions are not false, inaccurate, or misleading.</li>

      <li>
        Your Contributions are not unsolicited or unauthorized advertising,
        promotional materials, pyramid schemes, chain letters, spam, mass
        mailings, or other forms of solicitation.
      </li>

      <li>
        Your Contributions are not obscene, lewd, lascivious, filthy, violent,
        harassing, libelous, slanderous, or otherwise objectionable (as
        determined by us).
      </li>

      <li>
        Your Contributions do not ridicule, mock, disparage, intimidate, or
        abuse anyone.
      </li>

      <li>
        Your Contributions are not used to harass or threaten (in the legal
        sense of those terms) any other person and to promote violence against a
        specific person or class of people.
      </li>

      <li>
        Your Contributions do not violate any applicable law, regulation, or
        rule.
      </li>

      <li>
        Your Contributions do not violate the privacy or publicity rights of any
        third party.
      </li>

      <li>
        Your Contributions do not violate any applicable law concerning child
        pornography, or otherwise intended to protect the health or well-being
        of minors.
      </li>

      <li>
        Your Contributions do not include any offensive comments that are
        connected to race, national origin, gender, sexual preference, or
        physical handicap.
      </li>

      <li>
        Your Contributions do not otherwise violate, or link to material that
        violates, any provision of this License Agreement, or any applicable law
        or regulation.
      </li>
    </ol>

    <p>
      Any use of the Licensed Application in violation of the foregoing violates
      this License Agreement and may result in, among other things, termination
      or suspension of your rights to use the Licensed Application.
    </p>
   

    <p>
      We do not assert any ownership over your Contributions. You retain full
      ownership of all of your Contributions and any intellectual property
      rights or other proprietary rights associated with your Contributions. We
      are not liable for any statements or representations in your Contributions
      provided by you in any area in the Licensed Application. You are solely
      responsible for your Contributions to the Licensed Application and you
      expressly agree to exonerate us from any and all responsibility and to
      refrain from any legal action against us regarding your Contributions.
    </p>

    <p>
      We have the right, in our sole and absolute discretion, (1) to edit,
      redact, or otherwise change any Contributions; (2) to recategorize any
      Contributions to place them in more appropriate locations in the Licensed
      Application; and (3) to prescreen or delete any Contributions at any time
      and for any reason, without notice. We have no obligation to monitor your
      Contributions.
    </p>

       <h3><strong>License Agreement</strong></h3>
    <ol>

    <li>This license will also govern any updates of the Licensed
      Application provided by Licensor that replace, repair, and/or supplement
      the first Licensed Application, unless a separate license is provided for
      such update, in which case the terms of that new license will govern.
    </li>
    <li>You may not reverse engineer, translate, disassemble, integrate,
      decompile, remove, modify, combine, create derivative works or updates of,
      adapt, or attempt to derive the source code of the Licensed Application,
      or any part thereof (except with weWake LLC's prior written consent).
    </li>

    <li>You may not copy (excluding when expressly authorized by this
      license and the Usage Rules) or alter the Licensed Application or portions
      thereof. You may create and store copies only on devices that You own or
      control for backup keeping under the terms of this license, the Usage
      Rules, and any other terms and conditions that apply to the device or
      software used. You may not remove any intellectual property notices. You
      acknowledge that no unauthorized third parties may gain access to these
      copies at any time. If you sell your Devices to a third party, you must
      remove the Licensed Application from the Devices before doing so. The
      Licensor is solely responsible for providing any maintenance and support
      services for this Licensed Application. You can reach the Licensor at the
      email address listed in the App Store
    </li>

    <li>You acknowledge that Licensor will be able to access and adjust Your
      downloaded Licensed Application content and Your personal information, and
      that Licensor's use of such material and information is subject to Your
      legal agreements with Licensor and Licensor's privacy policy:
    </li>
    <li>You acknowledge that the Licensor may periodically collect and use
      technical data and related information about your device, system, and
      application software, and peripherals, offer product support, facilitate
      the software updates, and for purposes of providing other services to you
      (if any) related to the Licensed Application. Licensor may also use this
      information to improve its products or to provide services or technologies
      to you, as long as it is in a form that does not personally identify you.
    </li>

    <li>Licensor warrants that the Licensed Application is free of
      spyware, trojan horses, viruses, or any other malware at the time of Your
      download. Licensor warrants that the Licensed Application works as
      described in the user documentation.
    </li>


    <li>The license is valid until terminated by weWake LLC or by You. Your rights
      under this license will terminate automatically and without notice from
      weWake LLC if You fail to adhere to any term(s) of this license. Upon
      License termination, You shall stop all use of the Licensed Application,
      and destroy all copies, full or partial, of the Licensed Application.
    </li>

    <li>This License Agreement is governed by the laws of the Commonwealth of
      Massachusetts excluding its conflicts of law If any of the terms of this
      agreement should be or become invalid, the validity of the remaining
      provisions shall not be affected. Invalid terms will be replaced by valid
      ones formulated in a way that will achieve the primary purpose.
    </li>

    </ol>



  </div>
</template>

<script>
export default {
  name: "TermsOfUse",
};
</script>

<style scoped>
/* 
.appImg{
    width: 200px;
}

.form:hover{
    opacity: 0.8;
}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #4CD964;
}

.header{
  display: flex;
  align-items: center;
  justify-content: center;
} */

li {
  margin: 0 10px;
}

.hello {
  max-width: 50%;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 600px) {
  .hello {
    max-width: 80%;
  }
}
</style>
