<template>
  <div class="about">
    <TermsOfUse/>
  </div>
</template>

<script>
// @ is an alias to /src
import TermsOfUse from '@/components/TermsOfUse.vue'

export default {
  name: 'Terms',
  components: {
    TermsOfUse
  }
}
</script>