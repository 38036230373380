<template>
  <div class="hello">
    <h1>Privacy Policy</h1>
    <p>Last updated <strong> December 8, 2023 </strong></p>

    <p>We don't sell or share your data.</p>
    <p>
      To delete your data, click the "Delete Account" button at the base of the
      "Settings" page.
    </p>
  </div>
</template>

<script>
export default {
  name: "PrivacyPolicy",
};
</script>
